<template>
    <div class="header desktop" :class="{exhEdit:type=='edit'}">
        <img class="logo btn" src="@/assets/img/headLogo.svg" alt="" @click="backPage">
        <ul class="adminUl" v-if="type=='admin_manage'||type=='admin_letter'">
            <li :class="{active:type=='admin_manage'}" class='btn' @click="$router.push({name:'Admin'})"><img src="@/assets/img/nav/admin.png" alt="">Manage vendor</li>
            <!-- <li :class="{active:type=='admin_letter'}" class='btn' @click="$router.push({name:'AdminLetter'})"><img src="@/assets/img/nav/letter.png" alt="">Contact letter</li> -->
        </ul>
        <h3 v-if="type=='edit'">{{editingExh.title}}<span class="pen_w btn" @click="nameEditStatus=true"></span></h3>
        <input v-if="nameEditStatus" type="text" class="nameEditor" v-model="editName" v-on:keyup.enter="updateData">
        <ul class="exhEditNav1" v-if="type=='edit'">
            <li @click="openIntoEdit" class="btn">
                <img src="@/assets/img/nav/edit.svg" alt="">
                <img src="@/assets/img/nav/editA.png" alt="">
                <span>Info</span>
            </li>
            <li class="btn" @click="slideToggle=true">
                <img src="@/assets/img/nav/intro.svg" alt="">
                <img src="@/assets/img/nav/introA.png" alt="">
                <span>Learn</span>
            </li>
        </ul>
        <ul class="exhEditNav" v-if="type=='edit'">
            <li @click="saveScene" class="btn">
                <img src="@/assets/img/nav/save.svg" alt="">
                <img src="@/assets/img/nav/saveA.png" alt="">
                <span>Save</span>
            </li>
            <li class="btn" @click="preview()">
                <img src="@/assets/img/nav/preview.svg" alt="">
                <img src="@/assets/img/nav/previewA.png" alt="">
                <span>Preview</span>
            </li>
            <li>
                <el-form>
                    <el-form-item>
                        <el-switch v-model="open" @change="updateData('status')"></el-switch>
                    </el-form-item>
                </el-form>
            </li>
            <li class="btn" @click="leaveSceneAlert = true">
                <img src="@/assets/img/nav/exit.svg" alt="">
                <span>Exit</span>
            </li>
        </ul>
        <div class="user" v-if="type!='empty'&& type!='edit'&&type!='admin_manage'&&type!='admin_letter'" >
            <div class="avatar" :style="{backgroundImage: 'url(' + userImg + ')'}"></div>
            <!-- <p>{{vendor.companyName}}</p> -->
            <div class="union" @click="unionToggle=!unionToggle" v-click-outside="unionToggleMethod">
                <img src="@/assets/img/nav/chevronDown.svg" alt="">
                <div class="message" v-if="unionToggle">
                    <div class="poly"></div>
                    <p @click="headerLogout" v-if="type=='admin_manage'||type=='admin_letter'||type=='normal_m'||type=='normal_u'||type=='normal_c'" @mouseenter="hoverIcon=1" @mouseleave="hoverIcon=-1" :style="hoverIcon==1?{color:`#D2D2D2`}:{}">Logout(H1)</p>
                </div>
            </div>
        </div>
        <!-- <div class="user" v-if="type=='admin_manage'||type=='admin_letter'" >
            <div class="avatar" :style="{backgroundImage: 'url(' + userImg + ')'}"></div>
            Administrator
        </div> -->
        <div id="logout-btn" class="logout btn" @click="headerLogout" v-if="type=='admin_manage'||type=='admin_letter'" @mouseenter="hoverIcon=1" @mouseleave="hoverIcon=-1" :style="hoverIcon==1?{color:`#4454FF`}:{}">
            <img :src="hoverIcon==1?activeLogoutIcon:logoutIcon" alt="">
            Logout(H11)
        </div>
        <div class="bg" v-if="manageRoomPop">
            <div class="manageRoomPop">
                <h4>Manage rooms <span class="btn" @click="manageRoomPop=false"></span></h4>
                <p>You can decide whether the room is open or not in here</p>
                <div class="inputWrap" v-for="(item,index) in room" :key="'room'+index">
                    <input type="text" v-model="room[index].name" :disabled="!room[index].active" :class="{lock:!room[index].active}">
                    <el-form>
                        <el-form-item>
                            <el-switch v-model="room[index].active"></el-switch>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="inputWrap" v-for="(item,index) in (3-room.length)" :key="'lock'+index">
                    <input type="text"  disabled class="lock" v-model="locked">
                    <el-form>
                        <el-form-item>
                            <el-switch v-model="lock" disabled></el-switch>
                        </el-form-item>
                    </el-form>
                    <img src="@/assets/img/icon/locker_g.svg" alt="">
                </div>
                <button class="submit" @click="updateRoom">Save</button>
                <p class="btn" @click="openUpgrade">Learn about Premium Plans</p>
            </div>
        </div>
        <div class="saveAlert" v-if="leaveSceneAlert">
                <div class="container">
                    <img @click="leaveSceneAlert=false" class="btn" src="@/assets/img/icon/cross_black.svg" alt="">
                    <h4>Your exhibition has not been saved yet</h4>
                    <div class="btnWrap">
                        <button class="cancel" @click="leaveScene">Leave directly</button>
                        <button class="submit" @click="saveSceneLeave">Save</button>
                    </div>
                </div>
        </div>
        <div class="questionAlert" v-show="questionOpen">
            <div class="container">
                <img src="@/assets/img/question.png" alt="">
                <h4>Show contact information</h4>
                <p>Check this option can allow visitors to see your contact<br>information in the exhibition</p>
                <button class="submit" @click="closeQuestion">OK</button>
            </div>
        </div>
        <transition name="el-fade-in-linear"><Tutorial v-if="slideToggle" @closeSlide="closeSlide"/></transition>
    </div>
</template>
<script>
import headIcon from "@/assets/img/icon/userIcon.svg";
import gearIcon from "@/assets/img/icon/gear.svg";
import { mapGetters,mapActions } from "vuex";
import {getSellerExhDetail,updateSellerExhData,updateSellerRoomData,logout} from '@/api';
import Tutorial from '@/components/Tutorial'
//icons
import logoutIcon from "@/assets/img/icon/logout.svg";
import activeLogoutIcon from "@/assets/img/icon/logout_active_blue.svg";
export default {
    data(){
        return{
            room:[
            ],
            activeRoom:'first',
            open:true,
            manageRoomPop:false,
            leaveSceneAlert:false,
            slideToggle:false,
            lock:false,
            locked:'locked',
            roomIndex:0,
            logoutIcon:logoutIcon,
            activeLogoutIcon: activeLogoutIcon,
            hoverIcon: -1,
            unionToggle:false,
            editName:'',
            nameEditStatus:false
        }
    },
    components:{
        Tutorial
    },
    props:{
        type:{
            default:'empty'
        },
        editingScene:{
            default:''
        },
        editingSceneId:{
            default:''
        },
        questionOpen:{
            default:false
        }
    },
    computed:{
        ...mapGetters(["vendor","editingExh","exhData"]),
        userImg(){
            if(this.vendor.avatarUrl)
                return this.vendor.avatarUrl
            else if(this.type=='admin_letter'||this.type=='admin_manage')
                return gearIcon
            else 
                return headIcon
        }
    },
    mounted(){
        const vm = this;
        if(this.exhData.length!=0){
            this.getEditng()
        }
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://cdn.jsdelivr.net/npm/web3@1.8.2/dist/web3.min.js')
        document.head.appendChild(recaptchaScript)
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
        window.addEventListener("message", this.receiveMessage, false);
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////        
    },
    methods: {
        ...mapActions(["setEditingExh","clearSeller"]),
        goHome(){
            window.open(window.location.origin);
        },
        closeQuestion(){
            this.$emit('closeQuestionOpen')
        },
        openUpgrade(){
            this.$emit('openUpgrade')
        },
        saveSceneLeave(){
            this.saveScene()
            //this.$router.push({name:'VendorHome'})
            window.setTimeout(()=>{
                window.location.href = location.protocol+'//'+window.location.host+'/vendor';
            },1000)
        },
        closeSlide(){
            this.slideToggle = false
        },
        leaveScene(){
            //this.$router.push({name:'VendorHome'})
            window.location.href = location.protocol+'//'+window.location.host+'/vendor';
        },
        openIntoEdit(){
            this.$emit('openIntoEdit',true)
        },
        saveScene(){
            this.$emit('saveScene',true);
        },
        backPage(){
            this.$router.push({name:'LandingPage'})
        },
        headerLogout(){
            if(localStorage.getItem('type') == 'admin'){
                this.$router.push({name:'AdminLogin'});
            }else if(localStorage.getItem('type') == 'seller'){
                //this.$router.push({name:'LandingPage'});
                window.location.href = location.protocol+'//'+window.location.host+'/vendor';
            }
            else{
                window.location.href = location.protocol+'//'+window.location.host+'/vendor';
                //this.$router.push({name:'LandingPage'});
            }
            logout();
            this.clearSeller();
            document.cookie = `token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=.dlll.nccu.edu.tw; path=/; SameSite=None; Secure`;
        },

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
        receiveMessage(event) {
            if (event.origin !== "https://blockchain-asset-platform.dlll.nccu.edu.tw") {
                return;
            }
            console.log("Received message from " + event.origin + ": " + event.data);
            if (event.data === 'logout') {
                // setCookie(token, "", -1);
                this.headerLogout();
            }
        },
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

        async preview(){
            let vm  = this;
            await this.saveScene();
            window.open(location.protocol+'//'+window.location.host+'/exhibition?id='+vm.editingExh._id+'&room='+(parseInt(vm.roomIndex)+1)+'&watchType=preview');
            //window.location.href = location.protocol+'//'+window.location.host+'/exhibition?id='+vm.editingExh._id+'&room='+(parseInt(vm.roomIndex)+1)
        },
        async getEditng(){
            let exhId = this.$route.query.exh
            if(exhId==undefined)return
            exhId = parseInt(exhId);
            let res = await getSellerExhDetail(this.exhData[exhId]._id)
            this.setEditingExh(res.data.data);
            this.editName = this.editingExh.title;
            if(res.data.data.status=='publish'){
                this.open = true  
            }else{
                this.open = false  
            }
            this.setSelect()
        },
        setSelect(){
            this.room.splice(0,this.room.length)
            this.editingExh.rooms.forEach((item,index) => {
                let name = item.name
                let status = null
                if(name==""){
                    name = `room${index}`
                }
                if(item.status=='draft'){
                    status = false
                }else{
                    status = true
                }
                this.room.push({
                    value:name,
                    name:name,
                    active:status
                })
            });
            this.activeRoom = this.room[0].value;
        },
        async updateData(type){
            let tagArray = []
            let status = this.open ? 'publish':'draft'
            this.editingExh.tags.forEach((item,index)=>{
                tagArray.push(item.key)
            })
            let param = {
                signboardUrl: this.editingExh.signboardUrl,
                coverUrl: this.editingExh.coverUrl,
                title: this.editName,
                introduction: this.editingExh.introduction,
                tags: tagArray, // JSON string array
                status: status // publish or draft
            }
            let res = await updateSellerExhData(param,this.editingExh._id)
            if(type=='status'){
                this.$message({
                    showClose: true,
                    dangerouslyUseHTMLString: true,
                    message: this.open ? '<p style="color:#fff">Your block is online now</p>' : '<p style="color:#fff">Your block is offline now</p>',
                    type: 'success',
                    duration: 1500,
                });
            }
            this.getEditng();
            this.nameEditStatus = false;
        },
        async updateRoom(){
            let checkRoom = this.room.some((item,index)=>{
                return item.active
            })
            this.room.forEach(async (item,index)=>{
                let param 
                param = {
                    name: item.name, //optional
                    status: item.active?'publish':'draft', // one of ["public", "draft"],  optional
                }
                
                let res = await updateSellerRoomData(param,this.editingExh.rooms[index]._id)
            })
            this.getEditng()
            this.manageRoomPop = false
        },
        unionToggleMethod(){
            this.unionToggle = false;
        }
    }, 
    watch:{
        exhData(newV,oldV){
            if(newV!=[])
                this.getEditng()
        },
        activeRoom(newV,oldV){
            let vm = this
            if(this.room!=[]&&oldV!='first'){
                vm.room.forEach((item,index)=>{
                    if(item.name==vm.activeRoom)
                        this.roomIndex = index
                        vm.$parent.changeRoom(this.roomIndex)
                })
            }
        },
    }
}
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/_variables.scss';
    .header{
        display: flex;
        box-shadow: 0 1px 10px -3px rgb(0 0 0 / 50%);
        position: relative;
        background-color: #333333;
        align-items: center;
        height:64px;
        z-index: 2;
        .logo{
            width: 64px;
            margin: 15px 12% 15px 20px;
            @include for-size(tablet-landscape-only) {
                margin-right: 4%;
            }
        }
        &.exhEdit{
            .logo{
                margin-right: 40px;
            }
            @include for-size(tablet-landscape-only) {
                margin-right: 15px;
            }
        }
        .user{
            position: absolute;
            display: flex;
            align-items: center;
            color:black;
            font-size: 14px;
            font-weight: 400;
            right: 2%;
            top: 50%;
            transform: translateY(-50%);
            margin-right: 10px;
            // @include for-size(tablet-landscape-only) {
            //     right: 12%;
            // }

            p{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                max-width: 60px;
                font-size: 12px;
                margin: 5px 0;
                line-height: 1.5;
            }
        }
        .logout{
            position: absolute;
            display: flex;
            align-items: center;
            color:#fff;
            font-size: 14px;
            font-weight: 400;
            right: 3%;
            top: 50%;
            transform: translateY(-50%);
            img{
                margin-right: 10px;
            }
        }
        .avatar{
            width: 34px;
            padding-bottom:34px;
            background-size: 100%;
            margin-right: 10px;
            border-radius: 100%;
            background-position: center;
            min-width: 34px;
        }
        .union{
            position: relative;
            cursor:pointer;
            .message{
                position:absolute;
                background-color: #FFFFFF;
                border-radius:10px;
                padding: 10px;
                width: 77px;
                right: -125%;
                top: 220%;
                .poly{
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 0 11.5px 13px 11.5px;
                    border-color: transparent transparent #FFFFFF transparent;
                    position:absolute;
                    right: 10px;
                    top: -7px;

                }
                p{
                    color:#333333;
                }
            }
        }
        ul{
            display: flex;
            height: 100%;
            &.vendorHome{
                li{
                    img{
                        &:nth-of-type(2){
                            display: none;
                        }
                    }
                    // &:hover{
                    //     img{
                    //         &:nth-of-type(1){
                    //             display: none;
                    //         }
                    //         &:nth-of-type(2){
                    //             display: block;
                    //         }
                    //     }
                    // }
                    &.active{
                        img{
                            &:nth-of-type(1){
                                display: none;
                            }
                            &:nth-of-type(2){
                                display: block;
                            }
                        }
                    }
                }
            }
            li{
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-right: 150px;
                position:relative;
                color:#EFEFEF;
                padding: 0 10px;
                @include for-size(desktop-only) {
                    margin-right: 100px;
                }
                @include for-size(tablet-landscape-only) {
                    margin-right: 50px;
                }
                &:hover{
                    color:#D2D2D2;
                    background-color:#2E2E2E;
                }
                &.active{
                    color:#D2D2D2;
                    background-color:#2E2E2E;
                    &:before{
                        content:'';
                        height:5px;
                        width:100%;
                        background-color: #4454FF;
                        position: absolute;
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: 1px;
                    }
                }
                img{
                    margin-bottom: -2px;
                    margin-right: 10px;
                }
            }
        }
        .adminUl{
            img{
                width: 11%;
            }
        }
        h3,.nameEditor{
            font-size: 16px;
            font-weight: 400;
            margin: 0 8% 0 0;
            //font-size: 20px;
            color:#EFEFEF;
            position:absolute;
            left:50%;
            transform:translateX(-50%);
            @include for-size(desktop-only) {
                margin: 0 5% 0 0;
            }
            
            @include for-size(tablet-landscape-only){
                font-size: 14px;
                max-width: 106px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                margin-right: 20px;
            }

            .pen_w{
                background-image: url('~@/assets/img/nav/pen_w.svg');
                background-size: 100%;
                width: 12px;
                padding-bottom: 12px;
                display: inline-block;
                margin-left: 10px;
            }
        }
        .nameEditor{
            background-color:#000000;
            padding: 10px;
            border-radius: 2px;
            border:1px $purple solid;
        }
        ul{
            &.exhEditNav{
                align-items: center;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                //width: 800px;
                right: 0;
                width: 33%;
                // @include for-size(desktop-only) {
                //     width: 700px;
                //     right:0;
                // }
                // @include for-size(tablet-landscape-only){
                //     width:480px;
                // }
                li{
                    //margin-right: 45px;
                    //margin-right: 7.3%;
                    margin-right: 0;
                    font-size:14px;
                    height:100%;
                    box-sizing: initial;   
                    padding: 0 5%;
                    @include for-size(tablet-landscape-only){
                        font-size: 12px;
                        margin-right: 25px !important;
                    }
                    img{
                        &:nth-of-type(2){
                            display: none;
                        }
                        @include for-size(tablet-landscape-only){
                            display: none !important;
                        }
                    }
                    &:nth-of-type(3){
                        width: 63px;
                        margin-right: 11%;
                        ::v-deep .el-form{
                            width: 100%;
                        }
                        ::v-deep el-form-item{
                            width: 100%;
                        }
                    }
                    &:nth-of-type(1){
                        img{
                            width: 32%;
                        }
                    }
                    &:nth-of-type(2){
                        img{
                            width: 23%;
                        }
                    }
                    // &:nth-of-type(6){
                    //     img{
                    //         width: 25%;
                    //     }
                    // }
                    ::v-deep .el-form-item{
                        margin-bottom: 0;
                    }

                    
                }
            }
            &.exhEditNav1{
                position: relative;
                width: 155px;
                li{
                    width: 44%;
                    margin-right: 3%;
                    font-size:14px;
                    box-sizing: initial;
                    padding: 0 10px;
                    img{
                        &:nth-of-type(2){
                            display: none;
                        }
                        @include for-size(tablet-landscape-only){
                            display: none !important;
                        }
                    }
                    &:nth-of-type(1){
                        //width: 23%;
                        margin-right: 3%;
                        img{
                            width: 28%;
                        }
                    }
                    &:nth-of-type(2){
                        margin-right: 3%;
                        img{
                            width: 24%;
                        }
                    }
                }
            }
        }
        .bg{
            position: fixed;
            top:0;
            left:0;
            right:0;
            bottom:0;
            background-color: rgba(0,0,0,0.7);
            z-index: 99;
        .manageRoomPop{
                border-radius: 9px;
                overflow: hidden;
                width: 24.3%;
                position: relative;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                background-color: #fff;
                min-width: 400px;
                padding-bottom: 1%;
                h4{
                    font-size: $font*20;
                    position:relative;
                    background-color: #373B53;
                    line-height: 2.3;
                    color:#fff;
                    margin-top: 0;
                    margin-bottom: 7%;
                    @include for-size(desktop-only) {
                        font-size: 14px;
                    }
                    span{
                        background-image:url('~@/assets/img/icon/crossW.svg');
                        background-size: 100%;
                        width: 3%;
                        padding-bottom: 3%;
                        position: absolute;
                        top: 50%;
                        right: 3%;
                        transform:translateY(-50%);
                        @include for-size(tablet-landscape-only){
                            font-size: 12px;
                        }
                    }
                }
                p{
                    font-size: $font*14;
                    width: 55%;
                    margin: 0 auto 7%;
                    &:nth-of-type(2){
                        font-size: 14*$font;
                        color:$purple;
                        text-decoration: underline;
                        margin: 0 auto 5%;
                        @include for-size(desktop-only) {
                            font-size: 12px;
                        }
                    }
                }
                .inputWrap{
                    display: flex;
                    width: 55%;
                    margin: 0 auto 8%;
                    position:relative;
                    border-color: #B2B1B1;
                    align-items: center;
                    .el-form{
                        position: absolute;
                        right: -24%;
                        padding-bottom: 2%;
                    }
                    .el-form-item{
                        margin-bottom: 0;
                    }
                    ::v-deep .el-switch.is-checked .el-switch__core{
                        border-color: $purple;
                        background-color: $purple;
                    }
                    ::v-deep .el-switch.is-checked{
                        &:before{     
                            color:#fff;
                        }
                    }
                    ::v-deep .el-switch .el-switch__core{
                        border-color: #C7C7C8;
                        background-color: #C7C7C8;
                    }
                    input{
                        font-size: $font*16;   
                        line-height: 2.3;
                        width: 100%;
                        padding: 2px 13px;
                        border-color:#B2B1B1;
                        color:#282828;
                        &.lock{
                            color:#B2B1B1;
                            border-color:#B2B1B1;
                        }
                        @include for-size(desktop-only) {
                            font-size: 12px;
                        }
                    }
                    img{
                        position: absolute;
                        width: 11px;
                        left: -12%;
                    }
                }

                .submit{
                    font-size: $font*16;
                    width:55%;
                    margin: 0 auto 6%;
                    border-radius: 5px;
                    line-height: 2.5;
                    @include for-size(desktop-only) {
                        font-size: 12px;
                        border-radius: 7px;
                    }
                }
                
            }
    }
    .exhEditNav{

                ::v-deep .el-switch .el-switch__core{
                //width: 92px !important;
                height: 35px;
                border-radius: 30px;
                border-color: #C7C7C8;
                background-color: #C7C7C8;
                width: 100% !important;
                @include for-size(desktop-only) {
                    height: 30px;
                }
                @include for-size(tablet-landscape-only){
                    width: 445px;
                }
            }
            ::v-deep .el-form-item__content{
                line-height: 30px;
            }
            ::v-deep .el-switch.is-checked .el-switch__core{
                border-color: $purple;
                background-color: $purple;
            }
            ::v-deep .el-switch{
                width: 100%;
                height:30px;
                &:before{
                    content:'Offline';
                    position: absolute;
                    font-size: 14px;
                    top: 49%;
                    transform: translateY(-50%);
                    z-index: 2;
                    left:auto;
                    right: -90%;
                    color:#fff;
                }
            }

            ::v-deep .el-switch.is-checked{
                &:before{
                    content:'Online';
                }
            }
            ::v-deep .el-switch .el-switch__core::after{
                height: 31px;
                width: 31px;
                @include for-size(desktop-only) {
                    height: 26px;
                    width: 26px;
                }
            }
            ::v-deep .el-switch.is-checked .el-switch__core::after{
                margin-left: -32px;
                @include for-size(desktop-only) {
                    margin-left: -27px;
                }
            }
    }
            .select{
                // position: absolute;
                // right: 2%;
                // top: 11%;
                width: 150px;
                border: none;
                margin-right: 15px;
                input{
                    font-size: 20px;
                    border: none;
                    font-size: 20px;
                    font-weight: 400;
                }
                .el-select .el-input .el-select__caret{
                    color:#757575;
                    top: 2px;
                    font-weight: 900;
                }
                .el-select-dropdown__wrap{
                    margin-bottom: 0;
                }
            }
            .select ::v-deep.el-select .el-input.is-focus .el-input__inner{
                    padding: 0 20px 0 15px;
            }
            .manageRoom{
                width: 8%;
                display: flex;
                align-items: center;
                min-width: 115px;
                .inputWrap{
                    align-items: center;
                }
                img{
                    margin-right: 10px;
                    margin-top: 3px;
                    width: 12%;
                    &:nth-of-type(2){
                        display: none;
                    }
                    @include for-size(tablet-landscape-only){
                        display: none !important;
                    }
                }
                // &:hover{
                //     img{
                //         &:nth-of-type(1){
                //             display: none;
                //         }
                //         &:nth-of-type(2){
                //             display: inline-block;
                //         }
                //     }
                // }
                span{
                    font-size: 0.728vw;
                    color:#fff;
                    
                    @include for-size(tablet-landscape-only){
                        font-size:12px;
                    }
                }
                &:hover{
                    span{
                        color:#D2D2D2;
                    }
                }
            }

        .saveAlert{
            position:fixed;
            background-color: rgba($color: #000000, $alpha: 0.5);
            top:0;
            left:0;
            right:0;
            bottom:0;
            .container{
                background-color: #fff;
                width: 25%;
                position: absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                padding: 0.1% 0 2.8%;
                border-radius: 6px;
            }
            img{
                width:5%;
                margin:0 1% 7% auto;
                display: block;
            }
            .txt{
                width: 70%;
                margin:0 auto;
            }
            h4{
                font-size:20*$font;
                color:#444444;
                margin-bottom: 6%;
                font-weight: 500;
            }
            h5{
                font-size: 17*$font;
                color:#444444;
                margin-bottom: 6%;
                font-weight: 400;
                margin-top: 0;
                text-align: left;
                &:nth-of-type(1){
                    margin-bottom: 2%;
                }
                span{
                    color:#FF4CAD;
                }
            }
            .btnWrap{
                display: flex;
                justify-content: center;
                flex-wrap: wrap-reverse;
            }
            button{
                font-size: 17*$font;
                line-height: 2.3;
                width: 77%;
                border-radius: 4px;
                margin: 0 3%;
                @include blockShadow;
                &:nth-of-type(2){
                    margin-bottom: 3%;
                }
            }
        }
        .questionAlert{
            position:fixed;
            background-color: rgba($color: #000000, $alpha: 0.5);
            top:0;
            left:0;
            right:0;
            bottom:0;
            z-index: 999;
            min-width: 700px;
            .container{
                background-color: #fff;
                width: 55%;
                position: absolute;
                top:50%;
                left:50%;
                transform:translate(-50%,-50%);
                padding: 2.8% 0 2.8%;
                border-radius: 11px;
                border:2px #6F52ED solid;
                img{
                    width: 80%;
                }
                h4{
                    font-size: 20*$font;
                    @include for-size(tablet-landscape-only){
                        font-size: 16px;
                    }
                }
                p{
                    font-size: 17*$font;
                    line-height: 1.5;
                    margin-bottom: 3%;
                    @include for-size(tablet-landscape-only){
                        font-size: 12px;
                    }
                }
                button{
                    font-size: 17*$font;
                    line-height: 2.5;
                    min-width: 67px;
                    border-radius: 7px;
                    width: 10%;
                    @include for-size(tablet-landscape-only){
                        font-size: 12px;
                    }
                }
            }
        }
    }
</style>